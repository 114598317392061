body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.borderNone {
  border: none !important;
}

.borderRadius0 {
  border-radius: 0 !important;
}

.padding0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.paddingRight0 {
  padding-right: 0 !important;
}

.paddingLeft0 {
  padding-left: 0 !important;
}

.paddingTop0 {
  padding-top: 0 !important;
}

.paddingTop20 {
  padding-top: 20px !important;
}

.paddingTop25 {
  padding-top: 25px !important;
}

.paddingBottom0 {
  padding-bottom: 0 !important;
}

.marginRight0 {
  margin-right: 0 !important;
}

.marginLeft0 {
  margin-left: 0 !important;
}

.marginLeft30 {
  margin-left: 30px !important;
}

.marginTop0 {
  margin-top: 0 !important;
}

.marginTop5 {
  margin-top: 5px !important;
}

.marginTop10 {
  margin-top: 10px !important;
}

.marginTop20 {
  margin-top: 20px !important;
}

.marginBottom0 {
  margin-bottom: 0 !important;
}

.marginBottom10 {
  margin-bottom: 10px !important;
}

.marginBottom20 {
  margin-bottom: 20px !important;
}

.marginLeft10 {
  margin-left: 10px !important;
}

.marginRight5 {
  margin-right: 5px !important;
}

.marginRight10 {
  margin-right: 10px !important;
}

.marginRight20 {
  margin-right: 20px !important;
}

.marginRight25 {
  margin-right: 25px !important;
}

.marginRight30 {
  margin-right: 30px !important;
}

.alignCenter {
  align-content: center;
}

.italic {
  font-style: italic;
}

.pointer-on-hover:hover {
  cursor: pointer;
}

.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("./assets/pictures/facade.jpeg"); /* Add background image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 75% 25%;
}

.login-form {
  background-color: aliceblue;
  padding: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.login-form label {
  font-size: 18px !important;
  font-weight: bold !important;
}

.green {
  color: green;
  font-weight: bold;
}

.red {
  color: red;
  font-weight: bold;
}

.cursorNotAllowed {
  cursor: not-allowed !important;
}

.disabledDropdown {
  color: #888888 !important; /* More evident grayed-out text color */
  background-color: #f7f7f7 !important; /* More evident grayed-out background color */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}

.disabledLabel {
  color: #888888 !important; /* More evident grayed-out label color */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  font-style: italic;
}

.distinct-time-slot {
  border-top: 2px solid red; /* Customize the border style */
}

.distinct-time-slot:hover {
  background-color: yellow; /* Customize the background color */
}

.negative-input {
  border: 2px solid red;
}

.amountText {
  font-size: 20px;
  font-weight: bolder;
}

.fieldset {
  border-style: solid;
  border-width: thin;
}

.fieldsetBorderStyleNone {
  border-style: none;
}

.price {
  font-size: 20px;
  font-weight: bolder;
  padding-right: 10px;
}